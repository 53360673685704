import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine, RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm';
import './Navbar.css';

// Import different logos
import defaultLogo from '../../assets/logo81.png';
import missionLogo from '../../assets/logo90.png'; 
import servicesLogo from '../../assets/logo90.png'; 

const Navbar = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  
  const location = useLocation(); // Get current route

  // Define dynamic styles per route
  const logoMap = {
    "/": defaultLogo,
    "/aboutus": missionLogo,
    "/desdev": servicesLogo,
    "/ux_design": servicesLogo,
    "/digital-marketing": servicesLogo,
    "/careers": servicesLogo,
    "/portfolio": servicesLogo,
    "/requirements": servicesLogo,
  };

  const backgroundMap = {
    "/": "navbar-default",
    "/aboutus": "navbar-mission",
    "/desdev": "navbar-services",
    "/ux_design": "navbar-services",
    "/digital-marketing": "navbar-services",
    "/careers": "navbar-services",
    "/portfolio": "navbar-services",
    "/requirements": "navbar-services",
  };

  const navbarClass = backgroundMap[location.pathname] || "navbar-default";
  const logo = logoMap[location.pathname] || defaultLogo;

  // Determine link color
  const linkColor = location.pathname === "/" ? "white" : "black";

  return (
    <div className={`navbar ${navbarClass}`} id="nav">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <div className="navbar-links_container">
          <p><Link to="/" style={{ color: linkColor }} className="hover-underline-animation">Home</Link></p>
          <p><Link to="/aboutus" style={{ color: linkColor }} className="hover-underline-animation">About Us</Link></p>

          <div 
            className="navbar-links_services" 
            onMouseEnter={() => setDropdownVisible(true)} 
            onMouseLeave={() => setDropdownVisible(false)}
          >
            <p 
              onClick={() => setDropdownVisible(!dropdownVisible)} 
              className={`hover-underline-animation ${location.pathname === "/" ? "white-text" : "black-text"}`}
            >
              Services {dropdownVisible ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
            </p>

            {dropdownVisible && (
              <div className="dropdown">
                <p><Link to="/desdev" style={{ color: "#000" }}>Web Design Development</Link></p>
                <p><Link to="/ux_design" style={{ color: "#000" }}>UX/UI Design</Link></p>
                <p><Link to="/digital-marketing" style={{ color: "#000" }}>Digital Marketing</Link></p>
              </div>
            )}
          </div>

          <p><Link to="/careers" style={{ color: linkColor }} className="hover-underline-animation">Careers</Link></p>
          <p><a href="/portfolio" style={{ color: linkColor }} className="hover-underline-animation">Portfolio</a></p>
        </div>
      </div>

      <div className="navbar-sign">
        <button type="button" onClick={() => setIsContactFormOpen(true)}><b>Contact Us</b></button>
      </div>

      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color={linkColor} size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color={linkColor} size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <p><Link to="/" style={{ color: "#000" }} className="hover-underline-animation">Home</Link></p>
              <p><Link to="/aboutus" style={{ color: "#000" }} className="hover-underline-animation">About Us</Link></p>
              <p 
                onClick={() => setDropdownVisible(!dropdownVisible)} 
                style={{ color: "#000" }} 
                className="hover-underline-animation services__Link"
              >
                Services {dropdownVisible ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </p>
              {dropdownVisible && (
                <div className="navbar-menu_dropdown">
                  <hr className="navbar__dropdown__divider" />
                  <p><Link to="/desdev" style={{ color: "#000" }}>Web Design Development</Link></p>
                  <p><Link to="/ux_design" style={{ color: "#000" }}>UX/UI Design</Link></p>
                  <p><Link to="/digital-marketing" style={{ color: "#000" }}>Digital Marketing</Link></p>
                  <hr className="navbar__dropdown__divider2" />
                </div>
              )}
              <p><Link to="/careers" style={{ color: "#000" }} className="hover-underline-animation">Careers</Link></p>
              <p><a href="/portfolio" style={{ color: "#000" }} className="hover-underline-animation">Portfolio</a></p>
            </div>

            <div className="navbar-menu_container-links-sign">
              <button type="button" onClick={() => setIsContactFormOpen(true)}><b>Contact Us</b></button>
            </div>
          </div>
        )}
      </div>
      {isContactFormOpen && <ContactForm onClose={() => setIsContactFormOpen(false)} />}
    </div>
  );
};

export default Navbar;
