import React, { useEffect } from 'react';
import './Header.css';
//import CTA from '../../Components/CTA/CTA.jsx';
import ContactForm from '../../Components/ContactForm/ContactForm.jsx';

const Header = () => {
  useEffect(() => {
    const mouseShadow = document.createElement('div');
    mouseShadow.classList.add('mouse-shadow');
    document.body.appendChild(mouseShadow);

    const updateMouseShadow = (event) => {
      const { clientX: mouseX, clientY: mouseY } = event;
      mouseShadow.style.left = `${mouseX}px`;
      mouseShadow.style.top = `${mouseY}px`;
    };

    document.addEventListener('mousemove', updateMouseShadow);

    return () => {
      document.removeEventListener('mousemove', updateMouseShadow);
      document.body.removeChild(mouseShadow);
    };
  }, []);

  const [isContactFormOpen, setIsContactFormOpen] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  return (
    <>
      <header className="header section__padding" id="home">
        <div className="header-content" data-aos="fade-left">
          <h1 className="" data-aos="zoom-out-up" data-aos-delay="300">
            Creating Websites <span className='cursive'>Without</span> Limits
          </h1>
          <p data-aos="zoom-out-down" data-aos-delay="600">
            <span className="loader">
              <span className="words">
                <span className="word">Expert Web Design & Development</span>
                <span className="word">Innovative UX/UI Solutions</span>
                <span className="word">All-In-One Digital Marketing</span>
              </span>
            </span>
            <br />
            Building Websites That <strong>Captivate, Convert,</strong> & <strong>Last.</strong>
          </p>
          <div className="header-content__input">
            <button type="button" data-aos="zoom-out-up" onClick={() => window.location.href = "#services"}>
              Discover More
            </button>
          </div>
        </div>
        {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
      </header>
    </>
  );
};


export default Header;