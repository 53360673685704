import React, { useState } from 'react';
import './UXUIDesign.css';
import Feature from '../../Components/Feature/Feature.jsx';
import uxuiImg from '../../assets/uxuIDrawing.jpg';
import { FaUserAlt, FaDraftingCompass, FaUsers, FaMobileAlt } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import ContactForm from '../../Components/ContactForm/ContactForm.jsx'; // Import the ContactForm component

const WhyUsData = [
  {
    title: 'Our Design Philosophy',
    text: 'At DTD, our UX/UI design philosophy is centered around creating meaningful and memorable user experiences. We strive to understand your users\' needs, behaviors, and motivations to design interfaces that are not only visually appealing but also highly functional and user-friendly.'
  },
  {
    title: 'Our Design Process',
    text: (
      <div>
        <p>Our UX/UI design process is structured and collaborative, ensuring that every aspect of the design is meticulously planned and executed. Our process includes:</p><br/>
        <ul className='check-ul'>
          <li className='check-mark'><FaUserAlt /> User Research and Persona Development</li>
          <li className='check-mark'><FaDraftingCompass/> Wireframing and Prototyping</li>
          <li className='check-mark'><FaUsers /> User Testing and Feedback</li>
          <li className='check-mark'><FaMobileAlt /> Responsive and Adaptive Design</li>
        </ul>
      </div>
    )
  },
  {
    title: 'Why Choose Us',
    text: 'Choosing DTD for your UX/UI design needs means partnering with a team of passionate designers who are dedicated to enhancing user experiences. Our expertise in user-centered design ensures that your product not only looks great but also provides a seamless and intuitive user experience.'
  },
  {
    title: 'Design Tools & Technologies',
    text: 'We use state-of-the-art design tools and technologies to bring your vision to life. Our toolkit includes Sketch, Figma, Adobe XD, InVision, and more. These tools enable us to create detailed and interactive prototypes that accurately represent the final product.'
  }
];

const UXUIDesign = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // State to track whether the contact form is open

  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  return (
    <div id="ux_section__margin">
      <div className="ux-heading">
        <h1 id='uxui' className="">Transform Your Digital Experience<br/>with UX/UI Design</h1>
        <div className="ux-heading__subtext">
          <p>Discover Tailored UX/UI Design Packages</p>
          <div className='content_prices_btn'>
          <button type="button" onClick={() => window.location.href = "#ux_package__wrapper"}>Our Prices </button>
          </div>
          <img className='devs-img' src={uxuiImg} alt='image_' />
        </div>
      </div>

      <div className='features section__padding'>
        <div className='features-heading'>
          <h1 id="description-heading" className='gradient__text'>Unlock Your Digital Potential with Exceptional UX/UI Design</h1>
          <div>
          <p>
            In today's competitive digital landscape, a great user experience is crucial. At DTD, we understand the importance of creating intuitive and engaging interfaces that not only look good but also provide a seamless user experience. Let us help you transform your vision into a captivating digital experience.
          </p>

          <p>
            Our expert UX/UI design services will elevate your digital products, making them more user-friendly and aesthetically pleasing. From detailed user research to high-fidelity prototyping, we ensure every design decision is backed by data and user insights.
          </p>

          <p>
            Partner with us to create a digital experience that resonates with your audience and achieves your business goals. Let's embark on a journey to design products that not only meet but exceed user expectations.{' '}
            <a href="#package__wrapper" className='arrow-icon'>
              Click here to see packages! <IoIosArrowDown className="arrow-icon" />
            </a>
          </p>
          </div>
        </div>

        <div className="features-container">
          {WhyUsData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
      </div>

      <div className="wrapper" id='ux_package__wrapper'>
        <div className="pricing-table gprice-single" data-aos="slide-right">
          <div className="head">
            <h4 className="title">Basic UX/UI Package</h4>
            <h4 className='strt-from'>Starting from:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪1499</h2>
            </div>
            <ul>
              <li>User Research and Persona Development</li>
              <li>Wireframing for up to 5 screens/pages</li>
              <li>Basic User Testing (up to 5 users)</li>
              <li>Design Handoff</li>
              <li>Examples: Simple app designs, landing pages, basic website interfaces</li>
              <li>Tailored For: Startups, small businesses, personal projects, freelancers</li>
              <li>Add-On: UX/UI Package + Full Development</li>
              <p className='disclaimer'>*Prices may vary based on the project. Prices above are the minimum price per project<br/><br/>*Add-On needs to be requested</p>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>

        <div className="pricing-table gprice-single" data-aos="slide-up">
          <div className="head">
            <h4 className="title">Standard UX/UI Package</h4>
            <h4 className='strt-from'>Starting from:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪2999</h2>
            </div>
            <ul>
              <li>All Basic Package Features</li>
              <li>Advanced User Research (interviews, detailed personas)</li>
              <li>High-Fidelity Prototyping for up to 10 screens/pages</li>
              <li>User Testing and Feedback (up to 10 users)</li>
              <li>Design Handoff with style guides</li>
              <li>Examples: Business website interfaces, detailed app designs, user dashboards</li>
              <li>Tailored For: Growing startups, small to medium-sized businesses, non-profit organizations, service providers</li>
              <li>Add-On: UX/UI Package + Full Development</li>
              <p className='disclaimer'>*Prices may vary based on the project. Prices above are the minimum price per project<br/><br/>*Add-On needs to be requested</p>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>

        <div className="pricing-table gprice-single" data-aos="slide-left">
          <div className="head">
            <h4 className="title">Premium UX/UI Package</h4>
            <h4 className='strt-from'>Starting from:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪5999</h2>
            </div>
            <ul>
              <li>All Standard Package Features</li>
              <li>Comprehensive User Research (multiple methods, extensive personas)</li>
              <li>Custom UI Components</li>
              <li>Full Interactive Prototypes for up to 20 screens/pages</li>
              <li>Extensive User Testing and Feedback (up to 20 users, A/B testing)</li>
              <li>Design Handoff with detailed documentation</li>
              <li>Examples: Complex app interfaces, enterprise web solutions, custom dashboards</li>
              <li>Add-On: UX/UI Package + Full Development</li>
              <li>Tailored For: Medium to large businesses, enterprises, businesses requiring complex interfaces</li>
              <p className='disclaimer'>*Prices may vary based on the project. Prices above are the minimum price per project<br/><br/>*Add-On needs to be requested</p>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>
      </div>

      {/* Render ContactForm if isContactFormOpen is true */}
      {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
    </div>
  );
};

export default UXUIDesign;
