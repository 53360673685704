import React, { useState } from 'react';
import logo from '../../assets/logo86.png';
import './Footer.css';
import {FaXTwitter} from 'react-icons/fa6';
import { FaInstagram, FaImages, FaTiktok,FaMedium } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ContactForm from '../../Components/ContactForm/ContactForm.jsx';

const Footer = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // State to track whether the contact form is open

  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  return (
    <div className="footer section__padding" id='footer'>
      {/*<div className="footer-heading">
        *<h1 className="gradient__text">Step into the Future: Explore Our Portfolio</h1>
      </div>

      <div className="footer-btn">
        <p>Explore <a href="/port">Our Portfolio</a></p>
      </div>*/}

      <div className="footer-links">
        <div className="footer-links_logo">
         <Link to='/'><img src={logo} alt="logo" /></Link> 
          <p className='address'>Based In Ra'anana, Israel<br />All Rights Reserved</p>
        </div>
        <div className="footer-links_div">
          <h4>Links</h4>
          <p className='contact__icons'>
            <a href="https://www.freepik.com/images"><FaImages /> All Graphics Used for This Website<br /><br /></a>
            <a href="https://www.instagram.com/devtodefy?igsh=dTczNWs0NTM3ejhv&utm_source=qr"><FaInstagram /> Instagram<br /><br /></a>
            <a href="https://www.tiktok.com/@thedevtodefy/"><FaTiktok/> TikTok<br /><br /></a>
            <a href="https://x.com/DevToDefy"><FaXTwitter /> Twitter / X<br /><br /></a>
            <a href="https://medium.com/@devtodefy"><FaMedium /> Medium<br /><br /></a>
          </p>
        </div>
        <div className="footer-links_div">
          <h4>Company</h4>
          <Link to='/'><p>Home</p></Link>
          <p><Link to="/aboutus">About Us</Link></p>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
        </div>
        
        <div className="footer-links_div">
          <h4>Services</h4>
          <p><Link to="/desdev">Web Design & Development</Link></p>
          <p><Link to="/ux_design">UX & UI Design</Link></p>
          <p><Link to="/digital-marketing">Digital Marketing</Link></p>
        </div>

        <div className="footer-links_div">
          <h4>Get in touch</h4>
          {/* eslint-disable-next-line */}
          <p><a onClick={openContactForm}>Contact Us</a></p>
          <p><a href="tel:0549663157">054 966 3157</a></p>
          <p><a href="mailto:contact@devtodefy.com">contact@devtodefy.com</a></p>
        </div>
      </div>

      <div className="footer-copyright">
       <p>@ powered by Dev To Defy</p>
    </div>

      {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
      {/*--Design inspired and adapted from @JavaScriptMastery on Youtube*/}
    </div>
  );
};

export default Footer;
