import React, { useState } from 'react';
import './WebDesDev.css';
import Feature from '../../Components/Feature/Feature.jsx';
import devs from '../../assets/devs.jpg';
import { FaSearch, FaPalette, FaCode, FaRocket } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import ContactForm from '../../Components/ContactForm/ContactForm.jsx'; // Import the ContactForm component

const WhyUsData = [
  {
    title: 'Our Design Philosophy',
    text: 'At DTD, we believe that great design is more than just aesthetics. It’s about creating an intuitive and engaging experience for your users. Our design process is centered around understanding your brand, audience, and goals to deliver a website that stands out and performs.'
  },
  {
    title: 'Our Development Process',
    text: (
      <div>
        <p>From the initial consultation to the final launch, we follow a structured development process to ensure every project is delivered on time and within budget. Our process includes:</p><br/>
        <ul className='check-ul'>
          <li className='check-mark'><FaSearch /> Discovery and Planning</li>
          <li className='check-mark'><FaPalette/> Design and Prototyping</li>
          <li className='check-mark'><FaCode /> Development and Testing</li>
          <li className='check-mark'><FaRocket /> Launch and Optimization</li>
        </ul>
      </div>
    )
  },
  {
    title: 'Why Choose Us',
    text: 'With years of experience in web design and development, we offer unparalleled expertise and a commitment to excellence. Our team is dedicated to delivering solutions that not only meet but exceed your expectations.'
  },
  {
    title: 'Technology Stack',
    text: 'We leverage the latest technologies and tools to build modern, scalable, and secure websites. Our technology stack includes HTML, CSS, JavaScript, React, Node.js, and various CMS platforms like WordPress and Drupal. For more time-sensitive and heavy-duty websites, we utilize platforms such as WordPress or Wix Studio to ensure robust performance and flexibility.'
  }
];

const WebDesDev = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // State to track whether the contact form is open

  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  return (
    <div id="service_section__margin">
      <div className="service-heading">
        <h1 id='desdev' className="">Empower Your Online Presence<br/>with Custom Web Solutions</h1>
        <div className="service-heading__subtext">
          <p>Discover Tailored Web Design & Development Packages</p>
          <div className='content_prices_btn'>
          <button type="button" onClick={() => window.location.href = "#package__wrapper"}>Our Prices</button>
          </div>
          <img className='devs-img' src={devs} alt='image_' />
        </div>
      </div>

      <div className='features section__padding'>
        <div className='features-heading'>
          <h1 id="descriptionss-heading" className='gradient__text'>Unlock Your Digital Potential with Tailored Excellence</h1>
          <p>
            In today's dynamic digital landscape, your online presence is more than just a website; it's your <b>digital storefront</b>, your brand ambassador, and your <b>ticket to success</b>. At DTD, we understand the power of a <b>captivating online presence</b>, and we're here to <b>transform your vision into reality</b>.
          </p>

          <p>
            With our expert web design and development services, you'll unlock the full potential of your digital identity. From sleek and user-friendly interfaces to seamless functionality and <b>cutting-edge features</b>, we'll craft a website that not only <b>captures attention</b> but also <b>drives results</b>.
          </p>

          <p>
            Why settle for ordinary when you can have extraordinary? Partner with us, and let's embark on a journey to elevate your online presence, engage your audience, and achieve your business goals. It's time to stand out, make an impact, and thrive in the digital world. Let's build something extraordinary together.{' '}
            <a href="#package__wrapper" className ='arrow-icon'>
              Click here to see packages! <IoIosArrowDown className="arrow-icon" />
            </a>
          </p>
        </div>

        <div className="features-container">
          {WhyUsData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
      </div>

      <div className="wrapper" id='package__wrapper'>
        <div className="pricing-table gprice-single" data-aos="slide-right">
          <div className="head">
            <h4 className="title">Basic Package</h4>
            <h4 className='strt-from'>Starting from:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪1499</h2>
            </div>
            <ul>
              <li>Custom Website Design</li>
              <li>Responsive Development</li>
              <li>Basic SEO</li>
              <li>One-month Support</li>
              <li>Optional Maintenance: ₪149 - ₪199 / month</li>
              <li>Examples: Portfolios, Landing Pages, Simple Blogs, Brochure Sites</li>
              <li>Tailored For: Individuals, Freelancers, Small businesses, Personal projects, Startups that want a web presence</li>
              <p className='disclaimer'>*Prices may vary based on the project. Prices above are the minimum price per project</p>
            </ul>
            <div className="sign-up">
              {/* Open ContactForm when Begin Today is clicked */}
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>

        <div className="pricing-table gprice-single" data-aos="slide-up">
          <div className="head">
            <h4 className="title">Standard Package</h4>
            <h4 className='strt-from'>Starting from:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪2999</h2>
            </div>
            <ul>
              <li>All Basic Package Features</li>
              <li>Advanced SEO</li>
              <li>CMS Integration</li>
              <li>Initial Content Creation (up to 5 pages)</li>
              <li>Three-month Support Included</li>
              <li>Optional Maintenance: ₪249 - ₪449 / month</li>
              <li>Examples: Business Websites, Content-Rich Blogs, Service Sites, Non-Profit Sites</li>
              <li>Tailored For: Small to medium-sized businesses, Growing startups, Non-profit organizations, Service providers</li>
              <p className='disclaimer'>*Prices may vary based on the project. Prices above are the minimum price per project</p>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>

        <div className="pricing-table gprice-single" data-aos="slide-left">
          <div className="head">
            <h4 className="title">Premium Package</h4>
            <h4 className='strt-from'>Starting from:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪5999</h2>
            </div>
            <ul>
              <li>All Standard Package Features</li>
              <li>eCommerce Functionality</li>
              <li>Custom Web Applications</li>
              <li>Six-month Support Included</li>
              <li>Optional Maintenance: ₪549 - ₪849 / month</li>
              <li>Examples: E-commerce Stores, Membership Sites, Complex Web Applications, Enterprise Solutions</li>
              <li>Tailored For: Medium to large businesses, Established e-commerce businesses, Enterprises, Businesses requiring complex web applications</li>
              <p className='disclaimer'>*Prices may vary based on the project. Prices above are the minimum price per project</p>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>
      </div>

      {/* Render ContactForm if isContactFormOpen is true */}
      {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
    </div>
  );
};

export default WebDesDev;
