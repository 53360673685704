import React, {useState}from 'react';
import './Careers.css';
import { FaBriefcase, FaGraduationCap } from 'react-icons/fa';
import CareerForm from '../../Components/CareerForm/CareerForm'; 
import joinUs from '../../assets/4529196.jpg';
import growing from '../../assets/6770986.jpg';
import sitting from '../../assets/6736639.jpg';
import { Link } from'react-router-dom';

const Careers = () => {

  const [isCareerFormOpen, setIsCareerFormOpen] = useState(false); // State to track whether the contact form is open

  const openCareerForm = () => {
    setIsCareerFormOpen(true);
  };

  const closeCareerForm = () => {
    setIsCareerFormOpen(false);
  };


  return (
    <div className="career-container">
      <header className="header" style={{backgroundColor:'#fff'}}>
        <h1 className="gradient__text">Join Our Team</h1>
        <p className="subtitle">We are looking for great minds to join our family</p>
        <img className="joinUs" src={joinUs} alt="img" />
      </header>
      <section className="current-opportunities">
        <h2 className="section-title"><FaBriefcase /> Current Opportunities</h2>
        <div className="opportunity">
          <h3 className="opportunity-title"><FaGraduationCap /> Non-Paid Internship</h3>
          <p className="opportunity-description">
            Gain valuable experience and contribute to exciting projects. Our internships provide hands-on learning and real-world exposure.<Link to="/requirements" className='purple__dis'>*Requirements</Link>

            <ul className='careePositions'>
              <h3>Positions:</h3>
              <li>Front End Developer/Engineer</li>
              <li>Back End Developer/Engineer</li>
              <li>Full Stack Developer/Engineer</li>
              <li>UX/UI Designer</li>
            </ul>
          </p>
          <button onClick={openCareerForm} type="submit" className="submit-button">Apply</button>
        </div>
      </section>
      
      <section className="coming-soon">
      <img className="growing" src={growing} alt="img" />
        <h2 className="section-title">What's Coming</h2>
        <p className="coming-soon-description">
          We're constantly growing and will be offering paid positions, more internships, and collaborative opportunities soon. Stay tuned!
        </p>
        <img className="sitting" src={sitting} alt="img" />
      </section>
      {/* Render ContactForm if isContactFormOpen is true */}
      {isCareerFormOpen && <CareerForm onClose={closeCareerForm} />}
    </div>
  );
};

export default Careers;
