import React from 'react';
import './Portfolio.css';
import { restaurant, inno, ecommerce, blogsite } from './index';

function Portfolio() {
  const projects = [
    {
      title: 'Innovaira',
      description: 'Simple, Clean, Modern Website of a Small Accuistions Company',
      imageUrl: inno,
      link: 'https://innovaira.devtodefy.com/',
      caseStudyLink: '', // Replace with actual case study URL
    },
    {
      title: 'Giovani',
      description: 'A stunning and user-friendly website for a modern luxury restaurant.',
      imageUrl: restaurant,
      link: 'https://giovani.devtodefy.com/',
      caseStudyLink: '', // Replace with actual case study URL
    },
    {
      title: 'Nuvia',
      description: 'An engaging and seamless shopping experience for an online digital download store.',
      imageUrl: ecommerce,
      link: 'https://nuvia.devtodefy.com/', // Replace with actual URL
      caseStudyLink: '', // Replace with actual case study URL
    },
    {
      title: '',
      description: 'A clean and modern blog platform for content creators and readers.',
      imageUrl: blogsite,
      link: '', // Replace with actual URL
      caseStudyLink: '', // Replace with actual case study URL
    }
    // Add more projects as needed
  ];

  return (
    <div className="portfolio-page">
      <header className="header" style={{ backgroundColor: '#fff' }}>
        <h1 className="gradient-text" style={{color:'black'}}>Our Portfolio</h1>
        <p className="subtitle2">We push the boundaries of web design and development to create stunning, high-performing websites. Explore our portfolio to see how we can transform your digital presence.</p>
      </header>

      <section id="projects" className="project-showcase">
        <div className="projects">
          {projects.map((project, index) => (
            <div className="project" key={index}>
              <img src={project.imageUrl} alt={project.title} />
              <div className="project-info">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                <div className="project-buttons">
                  {/* Render "View Project" button only if the link exists */}
                  {(project.link || project.caseStudyLink) && (
                    <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-button">
                      View Project
                    </a>
                  )}
                  {/* Render "Read Case Study" button only if caseStudyLink exists */}
                  {project.caseStudyLink && (
                    <a href={project.caseStudyLink} target="_blank" rel="noopener noreferrer" className="case-study-button">
                      Read Case Study
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <p className="disclaimer"><br /><br />*These projects shown are examples of our work and may not represent current client websites. We showcase these projects to highlight our skills and capabilities in web design & development, as well as UX/UI.</p>
    </div>
  );
}

export default Portfolio;
