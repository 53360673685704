import React from 'react';
import './Packages.css';
import Article from '../../Components/Articles/Article';
import { pack05, pack04, pack03 } from './import.js';
import { Link } from 'react-router-dom';
import { FaLaptopCode, FaPencilRuler, FaPenNib, FaPalette,FaChartLine, FaUserCheck, FaRegEdit } from 'react-icons/fa';

const Packages = () => (
  <div className="mission_section__margin">
    <div className="mission-heading" id="services">
      <h1 className="gradient__text" data-aos="slide-left">Expertly Crafting Your Online Presence</h1>
      <div className="mission-heading__subtext" data-aos="slide-right">
        <p>
          <b>Innovative design.</b>&nbsp;<b>Flawless development.</b>&nbsp;<b>Supporting</b>&nbsp;Your Digital&nbsp;<b>Growth</b>.
        </p>
      </div>
    </div>

    <div className="pack-container">
      <div className="blog-container_groupC">
        
       {/* All-in-One Digital Marketing Package */}
        <Link to="/digital-marketing" className="package-article" data-aos="slide-right">
          <Article imgUrl={pack04} text={
            <div>
              <h2 className='pack-headers'>All-in-One Digital Marketing</h2>
              <ul className="custom-list">
                <li><FaChartLine style={{ marginRight: '8px' }} /> Comprehensive SEO services</li>
                <li><FaRegEdit style={{ marginRight: '8px' }} /> Ad Creative Development</li>
                <li><FaUserCheck style={{ marginRight: '8px' }} /> Social Media Strategy</li>
                <li><FaPenNib style={{ marginRight: '8px' }} /> Content Marketing</li>
              </ul>
              <button className='click-here'>Learn More</button>
            </div>
          } />
        </Link>

        {/* UX & UI Design Package */}
        <Link to="/ux_design" className="package-article" data-aos="slide-up">
          <Article imgUrl={pack03} text={
            <div>
              <h2 className='pack-headers_2'>UX & UI Design Packages</h2>
              <ul className="custom-list">
                <li><FaPalette style={{ marginRight: '8px' }} /> Comprehensive user experience (UX) design</li>
                <li><FaPalette style={{ marginRight: '8px' }} /> Visually appealing user interface (UI) design</li>
                <li><FaPencilRuler style={{ marginRight: '8px' }} /> Prototyping and interactive wireframing</li>
                <li><FaPencilRuler style={{ marginRight: '8px' }} /> Usability testing and feedback implementation</li>
              </ul>
              <button className='click-here'>Learn More</button>
            </div>
          } />
        </Link>

        {/* Web Design & Development Package */}
        <Link to="/desdev" className="package-article" data-aos="slide-left">
          <Article imgUrl={pack05} text={
            <div>
              <h2 className='pack-headers'>Web Design & Development Packages</h2>
              <ul className="custom-list">
                <li><FaLaptopCode style={{ marginRight: '8px' }} /> Custom website design tailored to your brand</li>
                <li><FaLaptopCode style={{ marginRight: '8px' }} /> Responsive development for all devices</li>
                <li><FaLaptopCode style={{ marginRight: '8px' }} /> Search engine optimization (SEO)</li>
                <li><FaLaptopCode style={{ marginRight: '8px' }} /> Ongoing maintenance and updates</li>
              </ul>
              <button className='click-here'>Learn More</button>
            </div>
          } />
        </Link>
      </div> 
    </div>
  </div>
);

export default Packages;
