import React from 'react';
import {Helmet}from'react-helmet';  // Helmet for SEO optimization
import Navbar from '../Components/Navbar/Navbar';
import CompanyMission from '../Sections/Mission/Mission';
import Footer from '../Containers/Footer/Footer';

const Mission = () => {
    return (
        <>
        <Helmet>
        <link rel="canonical" href="https://devtodefy.com/aboutus" />
      </Helmet>
            <Navbar /> 
            <CompanyMission />
            <Footer />
        </>
    );
};

export default Mission;