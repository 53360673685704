import React, { useState } from 'react';
import './ContentCreation.css';
import Feature from '../../Components/Feature/Feature.jsx';
import devs from '../../assets/contentCreate.jpg';
import { FaSearch, FaPalette, FaCode, FaRocket} from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import ContactForm from '../../Components/ContactForm/ContactForm.jsx'; // Import the ContactForm component

const WhyUsData = [
  {
    title: 'What We Offer',
    text: (
      <div>
        <ul className='check-ul'>
          <li className='check-mark'>
            <FaPalette style={{ marginRight: '8px' }} />
            Ad Creative Development: Create compelling ad creatives for social media and display ads, including designing visuals and writing copy.
          </li>
          <li className='check-mark'>
            <FaSearch style={{ marginRight: '8px' }} />
            SEO Audits and Strategy Consultations: Conduct a one-time SEO audit and provide a detailed report with recommendations.
          </li>
          <li className='check-mark'>
            <FaCode style={{ marginRight: '8px' }} />
            Social Media Strategy Packages: Provide a strategic plan for social media marketing, including platform selection and content ideas.
          </li>
        </ul>
      </div>
    )
  },
  {
    title: 'Why Choose Us',
    text: (
      <div>
        <ul className='check-ul'>
          <li className='check-mark'><FaPalette style={{ marginRight: '8px' }} /> High-quality ad creatives that capture attention and drive engagement.</li>
          <li className='check-mark'><FaSearch style={{ marginRight: '8px' }} /> Detailed SEO audits that provide actionable insights for improving website performance.</li>
          <li className='check-mark'><FaCode style={{ marginRight: '8px' }} /> Comprehensive social media strategies that help businesses stand out and connect with their audience.</li>
        </ul>
      </div>
    )
  },
  {
    title: 'Our Service Approach',
    text: (
      <div>
        <p>At DTD, we offer specialized services that are designed to enhance your digital presence and marketing effectiveness. Our approach combines strategic insights with creative solutions to deliver impactful results.</p>
        <p>Our process includes:<br /><br /></p>
        <ul className='check-ul'>
          <li className='check-mark'><FaSearch /> Discovery & Strategy</li>
          <li className='check-mark'><FaPalette /> Creative Development</li>
          <li className='check-mark'><FaCode /> Implementation & Optimization</li>
          <li className='check-mark'><FaRocket /> Reporting & Analysis</li>
        </ul>
      </div>
    )
  }
];


const ContentCreation = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); // State to track whether the contact form is open

  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  return (
    <div id="content_section__margin">
      <div className="content-heading">
        <h1 id='contentCreation' className="">All-in-One Digital Marketing</h1>
        <div className="content-heading__subtext">
          <p>Elevate your brand’s online presence with our comprehensive digital marketing solutions.</p>
          <div className='content_prices_btn'>
            <button type="button" onClick={() => window.location.href = "#content_package__wrapper"}>Our Prices</button>
          </div>
          <img className='devs-img' src={devs} alt='image_' />
        </div>
      </div>

      <div className='features section__padding'>
        <div className='features-heading'>
          <h1 id="descriptionssss-heading" className='gradient__text'>Elevate Your Brand with Comprehensive Digital Marketing Strategies</h1>
          <p>
            In the competitive world of digital business, a robust online presence is key to success. At DTD, we specialize in delivering results-driven digital marketing services that align with your business goals.
          </p>

          <p>
            From optimizing your website for search engines to managing your social media accounts and running targeted ad campaigns, our team of experts will ensure your marketing efforts are both strategic and effective.
          </p>

          <p>
            Choose us to drive your brand’s growth and achieve measurable results with our full suite of digital marketing services. Explore our packages below and let’s start crafting your success story.
            <a href="#content_package__wrapper" className='arrow-icon'>
              <br/><br/>Ready to Take Your Marketing to the Next Level? Explore our Packages Below and Let's Begin! <IoIosArrowDown className="arrow-icon" />
            </a>
          </p>
        </div>

        <div className="features-container">
          {WhyUsData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
      </div>

      <div className="wrapper" id='content_package__wrapper'>

        <div className="pricing-table gprice-single" data-aos="slide-right">
          <div className="head">
            <h4 className="title">SEO Audits and Strategy Consultations</h4>
            <h4 className='strt-from'>One-Time Fee:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪1,999
              </h2>
            </div>
            <ul>
              <li>What's Included:</li>
              <ul>
                <li>Keyword research, site structure analysis, and a detailed report with improvement recommendations.</li>
                <li>Provides in-depth insights to enhance your website’s SEO performance.</li>
              </ul>
              <ul>
                <p className='disclaimer'>*One-time fee varies depending on the size and complexity of the website.</p>
              </ul>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>

        <div className="pricing-table gprice-single" data-aos="slide-up">
          <div className="head">
          <h4 className="title">Ad Creative Development</h4>
          <h4 className='strt-from'>Per Campaign:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪1,199</h2>
            </div>
            <ul>
              <li>What's Included:</li>
              <ul>
                <li>Designing visuals and writing copy for social media and display ads.</li>
                <li>Creates impactful ad creatives that drive engagement and conversions.</li>
              </ul>
              <ul>
                <p className='disclaimer'>*Price varies based on complexity and number of revisions.</p>
              </ul>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>

        <div className="pricing-table gprice-single" data-aos="slide-left">
          <div className="head">
            <h4 className="title">Social Media Strategy Packages</h4>
            <h4 className='strt-from'>Per Strategy Plan:</h4>
          </div>
          <div className="content">
            <div className="price">
              <h2>₪2,499</h2>
            </div>
            <ul>
              <li>What's Included:</li>
              <ul>
                <li>Platform selection, content ideas, posting schedules, and engagement tips.</li>
                <li>Offers a comprehensive plan to enhance your social media presence and strategy.</li>
              </ul>
              <ul>
                <p className='disclaimer'>*Price varies based on the depth of strategy and number of platforms.</p>
              </ul>
            </ul>
            <div className="sign-up">
              <button onClick={openContactForm} className="btn bordered radius">Begin Today</button>
            </div>
          </div>
        </div>
      </div>

      {/* Render ContactForm if isContactFormOpen is true */}
      {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
    </div>
  )
}

export default ContentCreation;